<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";
import {timeUtil} from "@/helpers/time-util";
import {currencyHelper} from "@/helpers/currency-helper";
import {StripeCheckout} from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
    Layout,
    PageHeader
  },

  computed: {
    currencyHelper() {
      return currencyHelper
    },

    timeUtil() {
      return timeUtil
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {

      marketplaceOrderId: "",

      marketplaceOrder: null,
      marketplaceOrderProduct: null,
      marketplaceProductVariation: null,
      marketplaceProduct: null,

      stripeId: ""
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('marketplace-payment.title'),
          active: true
        }
      ]
    },

    createStripeSession() {
      const json = JSON.stringify({
        "marketplaceOrderId": this.marketplaceOrderId
      });

      axios.post(`/stripe/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        if (result.status === 204) {
          window.location = 'testmanager'
          return
        }

        const { data } = result
        this.stripeId = data.stripeId
        this.stripeSubmit()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    stripeSubmit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    async loadMarketPlaceOrder() {
      try {
        const {data} = await axios.get(`/marketplace/order/${this.marketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.marketplaceOrder = data
        await this.loadMarketplaceOrderProduct()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceOrderProduct() {
      try {
        const {data} = await axios.get(`/marketplace/order/product/${this.marketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.marketplaceOrderProduct = data
        await this.loadMarketplaceProductVariation()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceProductVariation() {
      try {
        const {data} = await axios.get(`/marketplace/product/variation/${this.marketplaceOrderProduct.marketplaceProductVariationId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.marketplaceProductVariation = data
        await this.loadMarketplaceProduct()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceProduct() {
      try {
        const {data} = await axios.get(`/marketplace/product/${this.marketplaceProductVariation.marketplaceProductId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.marketplaceProduct = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getTitle() {
      let value = ""
      if (this.marketplaceProduct) {
        value += this.marketplaceProduct.name

        if (this.marketplaceProductVariation && this.marketplaceProductVariation.title) {
          value += " o " + this.marketplaceProductVariation.title
        }
      } else if (this.marketplaceProductVariation && this.marketplaceProductVariation.title) {
        value += this.marketplaceProductVariation.title
      }

      return value
    }

  },

  async created() {
    const id = this.$route.params.id;
    if (!id) {
      return;
    }

    this.marketplaceOrderId = id
    await this.loadMarketPlaceOrder()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('marketplace-payment.title')" :items="getItems()" />
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4>Informacje o produkcie</h4>
            <template v-if="marketplaceOrder">

              <div>Nazwa: {{ marketplaceProduct ? marketplaceProduct.name : "-" }}</div>
              <div>Cena: {{ marketplaceOrder.priceTaxIncluded.toFixed(2) }}{{ currencyHelper.getCurrencyInfo(marketplaceOrder.currency ).symbol }}</div>
              <div>Wartość produktu: {{  getTitle() }}</div>
              <div>Data wygaśnięcia: {{ dateUtil.asDateNormal(marketplaceOrder.expiresAt) }}</div>

              <hr>
              <div>Kwota do zapłaty:</div>
              <div>{{ marketplaceOrder.priceTaxIncluded.toFixed(2) }}{{ currencyHelper.getCurrencyInfo(marketplaceOrder.currency).symbol }}</div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>

        <template v-if="marketplaceOrder">
          <div class="float-right">
            <b-button variant="danger" @click="createStripeSession">Kupuję i płacę</b-button>
          </div>
        </template>
      </div>
    </div>

    <stripe-checkout
        ref="checkoutRef"
        v-if="$store.getters['stripe/publishableKey']"
        :pk="$store.getters['stripe/publishableKey']"
        :session-id="stripeId"
    />
  </Layout>
</template>